let cardRefNo = "";
function callNative() {
  try {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;

    if (isAndroid) {
      nativeInterface.getAdditionalParams("getAdditionalParams", null);
    } else {
      window.webkit.messageHandlers.nativeInterface.postMessage({
        command: "getAdditionalParams",
      });
    }
  } catch (e) {
    alert("error call native : " + e);
  }
}
function onReceiveNativeCommand(command, payload) {
  try {
    if (command == "getAdditionalParams") {
      const data = JSON.parse(payload);
      cardRefNo = data.cardRefNo;
      document.getElementById("receiveCRefNo").click();
    }
  } catch (e) {
    alert("error receive : " + e);
  }
}
function getCardRefNo() {
  return cardRefNo;
}
